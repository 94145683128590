import React, { Fragment } from 'react';
import firebase from "../Firebase";
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";
const db = firebase.firestore().collection('users');
const storageRef = firebase.storage().ref();

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            pending: true,
        };
    }


    render() {
        return(
            <Fragment>
                 <Helmet>
                    <script src="/js/main.js" ></script>
                </Helmet>
                <header id="header" class="fixed-top d-flex align-items-center">
                <div class="container d-flex align-items-center">
                 <Link to="/home"   class="logo me-auto"><img style={{height: 74}} src="assets/img/diplogo.jpeg" alt="" /></Link>

                <nav id="navbar" class="navbar order-last order-lg-0">
                    <ul>
                    <li><Link class="close"to="/home">Accueil</Link></li>
                    {/*<li><a class="nav-link scrollto" href="#about">A Propos</a></li>*/}
                    <li class="dropdown"><a href="#"><span>Nos Services</span> <i class="bi bi-chevron-down"></i></a>
                        <ul>
                        <li><Link  class="close"  to="/servicesD">Services DIASPORA</Link></li>
                        <li><Link class="close"  to="/servicesC">Services CAMEROUN</Link></li>
                        {/*<li><Link class="close"  to="/dipcotravel">DiPCo Travel</Link></li>*/}
                        </ul>
                    </li>
                    <li ><Link class="close" to="/about"><span>Qui Sommes Nous</span></Link></li>
                    {/* <li><a class="nav-link scrollto " href="#portfolio">Portfolio</a></li> */}
                    {/* <li><a href="blog.html">Blog</a></li>
                    <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                        <ul>
                        <li><a href="#">Drop Down 1</a></li>
                        <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                            <ul>
                            <li><a href="#">Deep Drop Down 1</a></li>
                            <li><a href="#">Deep Drop Down 2</a></li>
                            <li><a href="#">Deep Drop Down 3</a></li>
                            <li><a href="#">Deep Drop Down 4</a></li>
                            <li><a href="#">Deep Drop Down 5</a></li>
                            </ul>
                        </li>
                        <li><a href="#">Drop Down 2</a></li>
                        <li><a href="#">Drop Down 3</a></li>
                        <li><a href="#">Drop Down 4</a></li>
                        </ul>
                    </li> */}
                    <li><Link class="close" to="/contact">Contactez-Nous</Link></li>
                    <li><Link class="close"  to="/faq">FAQ</Link></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav>  

                {/*<a href="#about" class="get-started-btn scrollto">Commencer</a>*/}
                </div>
               </header>

            </Fragment>

        )
    }
}

export default Header;
