import React, { Fragment } from 'react';
import About from '../component/About';
import Contact from '../component/Contact';
import { Count } from '../component/Count';
import  Faq  from '../component/Faq';
import { Footer } from '../component/Footer';
import Header from '../component/Header';
import Partern from '../component/Parterns';
import { Services } from '../component/Services';
import { TabSection } from '../component/TabSection';
import { Team } from '../component/Team';
import {Helmet} from "react-helmet";
import firebase from "../Firebase";
import Section from "../component/Section";

const db = firebase.firestore().collection('users');
const storageRef = firebase.storage().ref();

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            pending: true,
        };
    }


    render() {
        return(
            <Fragment>
                 <Helmet>
                    <script src="/js/main.js" type="text/javascript" ></script>
                </Helmet>
                <Section image="../../assets/img/Pic7.jpg" text1="Diaspo" text2="Placement" text3="Consulting" btn={<a href="#tabs" className="btn-get-started scrollto">DiPCo Travel</a>}
                 sl1="Accompagner." sl2="Faciliter." sl3="Placer." />
                <main id="main">
                    {/* <Partern /> */}

                   <TabSection />

                     {/*<Services/>*/}
                    {/*<About />*/}

                    {/*<Contact/>*/}
                    {/*<Faq/>*/}
                </main>
               
            </Fragment>
        )
    }
}

export default HomePage;
