import React from 'react';
import {Helmet} from "react-helmet";
import Section from "./Section";

export  class Services extends React.Component {
    render() {
        return(
            <>
            <Helmet>
            <title>Services Diaspora</title>
            </Helmet>
               <Section btn={<a href="#services" className="btn-get-started scrollto">Diaspora</a>} text1="Services" text2="DIASPORA" image="../../assets/img/achat.jpg" />
            <section id="services" class="services section-bg ">
                 <Helmet>
                    <script src="/js/main.js" ></script>
                </Helmet>
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                    <h2>Services Diaspora</h2>
                    </div>

                    <div class="row">
                    <div class="col-md-6">
                        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                        <i class="bi bi-briefcase"></i>
                        <h4><a >Le transfert d’argent</a></h4>
                        <p>Nous ne prélevons que 2500 FCFA pour toutes les transactions allant jusqu’à un million.</p>
                        <h4 ><a style={{color: '#30D52E'}}>Transfert d’argent vers le Cameroun</a></h4>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Si vous avez de l’argent à l’étranger et que vous souhaitez le faire parvenir au Cameroun, nous nous proposons de le faire pour vous.</span> </p>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Les paiements au Cameroun se font EXCLUSIVEMENT A DISTANCE par : <br />
                                                                                                                                                                        o	Orange Money<br />
                                                                                                                                                                        o	MTN Mobile Money<br />
                                                                                                                                                                        o	Express union Mobile Money<br />
                                                                                                                                                                        o	Emi Money, Express Union, Express Exchange<br />
                                                                                                                                                                        o	Virement bancaire
                                                                                                                                                                        </span> </p>
                        <h4 ><a style={{color: '#30D52E'}}>Transfert d’argent à partir du Cameroun</a></h4>
                        <p>Si vous avez de l’argent au Cameroun et que vous souhaitez le recevoir à l’étranger, c’est simple :</p>  
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Vous nous contactez.</span> </p>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Nous vous indiquons le numéro d’un compte bancaire, vous y faites le dépôt du montant que vous souhaitez envoyer, vous filmez le récépissé de dépôt, vous nous l’envoyez et nous faisons un virement bancaire ou un PayPal.</span> </p>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Vous pouvez également faire un dépôt dans l’un de nos comptes mobiles money et vous recevrez l’argent à l’étranger. Seulement, les frais de retrait seront dans ce cas supportés entièrement par vous. </span> </p>

                                                                                                                                          
                        </div>
                    </div>
                    <div class="col-md-6 mt-4 mt-md-0">
                        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                        <i class="bi bi-card-checklist"></i>
                        <h4><a href="#">Expédition des colis</a></h4>
                        <h4 ><a style={{color: '#30D52E'}}>Vendre des kilos</a></h4>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Vous quittez du Cameroun pour la France, l’Angleterre, le Canada, la Belgique, l’Allemagne, la Suisse, les Etats-Unis et bien d’autres destinations encore ?
                            Vous avez une ou deux valises vides ? Vous avez au moins 10 kg encore non <br />
                            Nous achetons votre valise, nous la remplissons et nous vous la remettons au moins 6 heures avant votre décollage. <br />
                            Nous nous assurons de la légalité du contenu de la valise en vérifiant systématiquement chaque paquet ou enveloppe qui y entre. Une dernière vérification est également effectuée en votre présence avant la remise de la valise. 
                            </span> </p>
                        <h4 ><a style={{color: '#30D52E'}}>Acheter des kilos </a></h4>
                        <p style={{display: 'flex', flexDirection: 'row' }}><i style={{fontSize: 18, marginTop: 11, marginRight: 3}} class="ri-check-double-line"></i> <span>Vous voulez faire expédier une enveloppe ou des documents à partir du Cameroun ? 
                            Vous avez de la marchandise, des paquets ou des colis au Cameroun et vous voulez les recevoir dans un pays quelconque ? <br />
                            Nous disposons d’au moins 5 valises de 23 kg par semaine. <br />
                            Nous nous assurons que vos colis arrivent à destination en toute sécurité. <br/>
                            Une fois dans votre pays de destination, votre coli/enveloppe peut vous être expédié par la poste ou alors vous pouvez le récupérer en personne. <br/>
                            Dans les deux cas, nous vous mettons en contact direct avec la personne ayant voyagé avec votre coli/enveloppe. 
                            </span> </p>

                        </div>
                    </div>
                    
                    </div>

                </div>
            </section>
            </>
        )
    }
}