import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import Section from "./Section";

class ServiceCamer extends Component {
    render() {
        return (
            <>
            <Helmet >
                 <title>Services Cameroun</title>
            </Helmet>
                <Section btn={<a href="#servicescamer" className="btn-get-started scrollto">Cameroun</a>} text1="Services" text2="CAMEROUN" image="../../assets/img/lg1.jpg" />
                <section id="servicescamer" className="services section-bg ">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Services Cameroun</h2>
                        </div>



                        <div className="row">
                            <div className="col-md-6 mt-4 mt-md-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
                                    <i className="bi bi-calendar4-week"></i>
                                    <h4><a>Le Placement</a></h4>
                                    <p>Notre service de placement se veut être le pont entre vous et votre futur employeur,
                                        entre vous et votre futur employé(e)</p>
                                    <h4><a style={{color: '#30D52E'}}>Vous êtes une entreprise</a></h4>
                                    <p>Si vous êtes une entreprise voulant agrandir son personnel, nous vous proposons de
                                        nous contacter afin de nous confier la gestion en amont de votre processus de
                                        recrutement. <br/> Nous nous chargeons de : <br/></p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Diffuser votre offre d’emploi sur nos canaux de communication.</span>
                                    </p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>De recevoir les candidatures.</span></p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>De les étudier</span></p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Vous proposez une shortlist de 3 à 5 profils.</span>
                                    </p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i>
                                        <span>Et ce sera à vous de faire le reste.</span></p>
                                    <p>Nous vous ferons ainsi gagner en temps et vous pouvez être certain de la qualité des
                                        profils qui vous seront suggérés</p>
                                    <h4><a style={{color: '#30D52E'}}>Vous êtes chercheur d’emploi</a></h4>
                                    <p>Nous mettons à votre disposition notre service de placement. En fonction de votre
                                        profil et de vos souhaits, nous vous proposons les offres d’emploi qui vous
                                        correspondent</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mt-md-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
                                    <i className="bi bi-calendar4-week"></i>
                                    <h4><a>DiPCo Cleaning Service</a></h4>
                                    <p>DiPCo Cleaning Service est notre service de nettoyage professionnel. Nos équipes
                                        suivent des formations continues et nous assurons auprès de vous que leur rendement
                                        est à la hauteur de vos attentes. <br/>
                                        Nous travaillons sur la base de votre calendrier. Nos équipes sont très flexibles et
                                        peuvent être mise à votre disposition en matinée, en journée ou le soir </p>
                                    <h4><a style={{color: '#30D52E'}}>Entreprise ou institution</a></h4>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Si vous êtes une entreprise ou une institution privée ou publique, vous pouvez-vous offrir les services d’un personnel professionnel, formé et spécialisé dans l’entretien de vos bureaux.</span>
                                    </p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Nous travaillons sur la base de notre contrat avec vous. Nos équipes sont disponibles en tout temps et à tout moment suivant uniquement le programme de travail qui, leur sera remis.</span>
                                    </p>

                                    <h4><a style={{color: '#30D52E'}}>Domicile privé</a></h4>
                                    <p>Nos équipes peuvent se charger de la propreté dans votre domicile privé, en toute
                                        discrétion et dans le strict respect de votre intimité et de vos biens. Vous pouvez
                                        faire confiance à nos équipes</p>
                                    <h4><a style={{color: '#30D52E'}}>iii. Cérémonie</a></h4>
                                    <p>Si vous organisez une cérémonie (mariage, dot, fiançailles, baptême, anniversaire,
                                        funérailles, etc.), confiez-nous le nettoyage après la cérémonie</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mt-md-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
                                    <i className="bi bi-calendar4-week"></i>
                                    <h4><a>Entrepôt</a></h4>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Vous êtes entrepreneur.</span></p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Vous avez besoin d’un point de référence pour le stockage de vos produits afin, de faciliter la distribution.</span>
                                    </p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span>Vous avez besoin d’un lieu de pick-up de vos produits par vos clients en toute sécurité</span>
                                    </p>
                                    <p style={{display: 'flex', flexDirection: 'row'}}><i
                                        style={{fontSize: 18, marginTop: 11, marginRight: 3}}
                                        className="ri-check-double-line"></i> <span> Nous mettons à votre disposition des espaces d’entrepôts sécurisés dans nos locaux. Vos produits peuvent rester chez nous le plus longtemps possible, selon les termes de notre contrat avec vous.</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default ServiceCamer;