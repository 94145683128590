import React from 'react';
import './App.css';
import {Route, BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';

import HomePage from "./pages/HomePage";
import {TabSection} from "./component/TabSection";
import Header from "./component/Header";
import {Footer} from "./component/Footer";
import About from "./component/About";
import {Services} from "./component/Services";
import ServiceCamer from "./component/ServiceCamer";
import Contact from "./component/Contact";
import Faq from "./component/Faq";
import PreLoader from "./component/loader";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pending: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                pending: false
            })
        }, 3000)
    }

    render() {
        // if (this.state.pending) return  <PreLoader />

        return (
                <div>

                        <Router>
                            <Header />
                            <Switch>
                                <Redirect from="/" to="/home" exact/>
                                <Route exact path='/home' component={HomePage}/>
                                <Route exact path='/dipcotravel' component={TabSection} />
                                <Route exact path='/about' component={About} />
                                <Route exact path='/servicesD' component={Services} />
                                <Route exact path='/servicesC' component={ServiceCamer} />
                                <Route exact path='/contact' component={Contact} />
                                <Route exact path='/faq' component={Faq} />

                            </Switch>
                            <Footer />
                        </Router>

                </div>
        );
    }
}

export default App;
