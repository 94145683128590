import React from 'react';
import firebase from '../Firebase'
import {Helmet} from "react-helmet";
import Section from "./Section";

const db = firebase.firestore()
const storageRef = firebase.storage().ref();


export default class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            message: {
                sent: false,
                value: ""
            },
            files: [],
            pending: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this)
    }


    handleChange(e) {
        this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })
    }

    handleFileChange(e) {
        const tabImage = []
        for (let i = 0; i < e.target.files.length; i++) {
            const newFile = e.target.files[i];
            newFile["id"] = Math.random();
            console.log(newFile);
           tabImage.push(newFile)
        this.setState({files: tabImage})
        }
    } 

   async uploadImageMessage  (id, file) {
        
        let imgName = 'messages' + '/' + id 

        return await storageRef.child(imgName).child(file.name).put(file)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        }).catch((e) => {
          console.log(e);
        
        })

    }

    handleSubmit(e) {
        e.preventDefault();

        console.log(this.state.files);
        const { name,  email, phone, message, country, town,howfindus } = this.state.values;
       if (name && email && message, howfindus) {
            console.log(name, phone, email, message,  country, town,howfindus );
            let phoneuser = phone.split(" ").join("")
            if (phoneuser.startsWith("00")) {
                phoneuser = phoneuser.replace("00", "+")
            }
            if (phoneuser.startsWith("+")) {
                this.setState({pending: true})
                let createAt = new Date().getTime();
                let code = createAt.toString(36).toUpperCase();
                let images = []
                    if (this.state.files) {
                        this.state.files.forEach( async (file) => {
                             await  this.uploadImageMessage(code, file).then((url) => {
                                images.push(url)
                                db.collection("user-messages").doc(code).set({
                                    createAt: createAt,
                                    name: name.trim(),
                                    phoneuser,
                                    email: email.trim(),
                                    message: message.trim(),
                                    country,
                                     town,
                                     howfindus,
                                     url: images
                                }, {merge: true}).then((doc) => {
                                    
                                    this.setState({ message: { sent: true, value: "Votre message à été envoyé" } })
                                }).catch((e) => {
                                    this.setState({message: {sent: false, value: "une erreur est survenue lors de l'envoie du message"}})
                                })
                             }).catch((e) => {
                                db.collection("user-messages").doc(code).set({
                                    createAt: createAt,
                                    name: name.trim(),
                                    phoneuser,
                                    email: email.trim(),
                                    message: message.trim(),
                                    country,
                                     town,
                                     howfindus
                                }, {merge: true}).then((doc) => {
                                    
                                    this.setState({ message: { sent: true, value: "Votre message à été envoyé" } })
                                }).catch((e) => {
                                    this.setState({message: {sent: false, value: "une erreur est survenue lors de l'envoie du message"}})
                                })
                             })
                               
                        })
                    }
            } else {
                this.setState({ message: { sent: false, value: "Renseignez l'indicatif de votre pays sans oublier le +" } })
        }
       } else {
        this.setState({ message: { sent: false, value: "Renseignez les champs permettant de vous identifier" } })
       }
    }


    render() {

        return (
            <>
                <Section text1="Pour toutes" text2="Préocupations..." btn={<a href="#contact" className="btn-get-started scrollto">Contactez-Nous</a>}  image="../../assets/img/banner.jpg" />
                <section id="contact" className="contact">
                    <Helmet>
                        <script src="/js/main.js"/>
                        <title>Contactez DiPCo pour votre placement</title>
                    </Helmet>
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Contactez-nous</h2>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="100">

                            <div className="col-lg-6">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="info-box">
                                            <i className="bx bx-map"></i>
                                            <h3>Notre Addresse</h3>
                                            <p>Cameroun, Yaoundé, Carrefour Messassi, immeuble Express Exchange, en face de la station Total</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="info-box mt-4">
                                            <i className="bx bx-envelope"></i>
                                            <h3>Email</h3>
                                            <p> <a href="mailto:administration@dipcogroup.com" target="Blank" >administration@dipcogroup.com</a>  <br/> <a href="mailto:contact@dipcogroup.com" target="Blank">contact@dipcogroup.com</a> </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="info-box mt-4">
                                            <i className="bx bx-phone-call"></i>
                                            <h3>Telephone</h3>
                                            <p>+237 652597623<br/>+237 697704965<br/>+49 1573 0319385 </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <form role="form" className="php-email-form" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col form-group">
                                            <input type="text" name="name" className="form-control" id="name"
                                                   onChange={this.handleChange} placeholder="Votre Nom et Prenom"/>
                                        </div>
                                        <div className="col form-group">
                                            <input type="email" className="form-control" onChange={this.handleChange}
                                                   name="email" id="email" placeholder="Votre Email"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="phone" id="phone"
                                               onChange={this.handleChange}
                                               placeholder="Numero de telephone avec indicatif du pays"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" onChange={this.handleChange} name="message"
                                                  rows="3" placeholder="Entrer le Message"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="col form-group">
                                            <input type="text" name="country" onChange={this.handleChange}
                                                   className="form-control" id="pay" placeholder="Pays de residence"/>
                                        </div>
                                        <div className="col form-group">
                                            <input type="text" className="form-control" onChange={this.handleChange}
                                                   name="town" id="town" placeholder="Ville de residence"/>
                                        </div>
                                    </div>
                                    <div className="col form-group">
                                            <input type="text" name="howfindus"  onChange={this.handleChange}
                                                   className="form-control" id="howfindus" placeholder="Comment vous nous avez trouver?"/>
                                        </div>
                                    <div className="form-group">
                                        <label>joindre un ou plusieurs fichiers</label>
                                        <input multiple type="file" onChange={this.handleFileChange}
                                               className="form-control" name="file" id="file"
                                               placeholder="Joindre des fichiers"/>
                                    </div>
                                    <div className="my-3">
                                        {this.state.pending && <div className="loading">Loading</div>}
                                        {(this.state.message.value != "" && this.state.message.sent == false) &&
                                        <div className="alert alert-warning alert-dismissible fade show"
                                             role="alert"> {this.state.message.value} </div>}
                                        {(this.state.message.value != "" && this.state.message.sent == true) &&
                                        <div className="alert alert-success alert-dismissible fade show"
                                             role="alert">{this.state.message.value} </div>}
                                    </div>
                                    <div className="text-center">
                                        <button type="submit">Envoyer Le Message</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>

            </>
        )
    }
}