import React from 'react';
import {Helmet} from "react-helmet";
import Section from "./Section";

export class TabSection extends React.Component {
    render() {
        return(
            <>
                <section id="tabs" className="tabs">
                    <Helmet>
                        <script src="/js/main.js"></script>
                        <title>Venir au cameroun | Cours de Langu</title>
                    </Helmet>
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>DiPCo Travel</h2>
                        </div>

                        <ul className="nav nav-tabs row d-flex">
                            <li className="nav-item col-4">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <i className="bx bxs-plane-alt"></i>
                                    <h4 className="d-none d-lg-block">Vous êtes encore au cameroun </h4>
                                </a>
                            </li>
                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    {/* <i className="bx bxs-plane-alt"></i> */}
                                    <span style={{fontSize: 50}} className='iconify' data-icon="bx:bxs-plane-alt" data-flip="vertical"></span>
                                    <h4 className="d-none d-lg-block">Venir au Cameroun</h4>
                                </a>
                            </li>
                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <i className="bx bx-edit"></i>
                                    <h4 className="d-none d-lg-block">Cours de Langue</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active show" id="tab-1">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h3>Vous êtes encore au Cameroun</h3>
                                        <p className="fst-italic">
                                            Vous avez pour ambition de vous installer en Europe ou en Amérique, Nous
                                            vous
                                            accompagnons dans votre processus d’émigration. <br/>
                                            Peu importe le type de visa via lequel vous souhaitez voyager, nous vous
                                            garantissons un accompagnement professionnel jusqu’à l’atteinte de
                                            l’objectif final
                                            qui, est l’obtention de votre visa.
                                        </p>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i> Nous nous chargeons de
                                                rassembler tous les documents nécessaires pour
                                                votre demande de visa
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Nous vous préparons pour
                                                l’entretien du visa au consulat ou l’ambassade.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> En cas de refus du visa, nous
                                                étudions les motifs du refus et nous vous
                                                accompagnons dans l’introduction d’une requête pour contestation de la
                                                décision du consulat
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Si nécessaire, nous vous
                                                récupérons à l’aéroport une fois à destination.
                                            </li>

                                        </ul>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up"
                                         data-aos-delay="200">
                                        <img src="assets/img/Pic7.jpg" alt="" className="img-fluid"/>
                                    </div>

                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Visa Etudiant</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i> Nous étudions la faisabilité de
                                                votre dossier d’étudiant sur la base de votre
                                                profil académique et des exigences de votre potentiel pays d’accueil.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Nous nous chargeons de
                                                rassembler la paperasse administrative
                                                nécessaire pour votre candidature dans une ou plusieurs universités de
                                                votre choix ou du pays dans lequel vous souhaitez pour suivre vos
                                                études.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Nous vous obtenons une ou
                                                plusieurs admissions dans les universités
                                                ciblées
                                            </li>
                                            <li><i className="ri-check-double-line"></i> <span
                                                style={{fontWeight: 'bold'}}>Avantage du visa étudiant</span> : vous
                                                avez le droit de travailler à temps partiel
                                                dans la plupart des cas une fois dans votre pays d’accueil ou après
                                                quelques mois(4-6 mois), le temps de remplir quelques procédures
                                                administratives locales.
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Visa formation
                                            professionnelle</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i> Nous étudions la faisabilité de
                                                votre dossier sur la base de votre profil
                                                académique et des exigences de votre potentiel pays d’accueil
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Nous nous chargeons de
                                                rassembler la paperasse administrative
                                                nécessaire pour votre dossier.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Grace à notre réseau de
                                                partenaires, nous vous obtenons un contrat de
                                                formation professionnelle
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Si nécessaire, nous vous
                                                préparons pour l’entretien d’embauche avec votre
                                                potentiel futur employeur.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> <span
                                                style={{fontWeight: 'bold'}}>Avantage du visa formation professionnelle</span> :
                                                vous avez des revenus
                                                garantis et stables dès le premier mois de votre arrivée dans votre pays
                                                d’accueil. Vous allez à l’école et vous travailler parallèlement, selon
                                                les
                                                termes de votre contrat.
                                                à temps plein, dans la plupart des cas.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Visa Travail</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Vous avez terminé vos études au
                                                Cameroun ?
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Vous souhaitez travailler à
                                                l’étranger ?
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Nous nous chargeons de vous
                                                trouver un contrat de travail dans votre pays
                                                cible.
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Si nécessaire, nous vous
                                                préparons pour l’entretien d’embauche avec
                                                votre potentiel futur employeur.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> <span
                                                style={{fontWeight: 'bold'}}>Avantage du visa travail</span> : vous avez
                                                des revenus garantis et stables dès
                                                le premier mois de votre arrivée dans votre pays d’accueil. Vous
                                                travaillez
                                                à temps plein, dans la plupart des cas.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Visa Au pair</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Vous souhaitez voyager en tant
                                                que garçon ou fille au-pair ?
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Nous nous chargeons de vous
                                                trouver une famille d’accueil.
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Nous vous préparons pour
                                                l’entretien avec les familles intéressées par
                                                votre profil.
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Nous nous assurons que vous
                                                obtiendrez bel et bien un contrat.
                                            </li>
                                            <li><i className="ri-check-double-line"></i><span
                                                style={{fontWeight: 'bold'}}>Avantage du visa au-pair</span> : Vous
                                                pouvez décider de vous installer dans
                                                votre pays d’accueil et obtenir un titre de séjour en tant qu’étudiant
                                                ou
                                                étudiante ou alors vous pouvez faire une formation professionnelle.
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Volontariat</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Vous souhaitez faire une année
                                                de volontariat à l’étranger ?
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Grace à notre réseau, nous vous
                                                obtenons un contrat de volontariat à
                                                l’étranger et nous suivons votre dossier de visa jusqu’au bout.
                                            </li>

                                            <li><i className="ri-check-double-line"></i><span
                                                style={{fontWeight: 'bold'}}>Avantage du visa volontariat : </span>Vous
                                                pouvez décider de vous installer
                                                dans votre pays d’accueil et obtenir un titre de séjour en tant
                                                qu’étudiant ou
                                                étudiante ou alors vous pouvez faire une formation professionnelle.
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Visa touriste</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Vous voulez séjourner pendant
                                                moins de 90 jours à l’étranger pour
                                                diverses raisons (soins médicaux, rendez-vous d’affaire, vacances,
                                                tourisme)
                                            </li>
                                            <li><i className="ri-check-double-line"></i>Si vous voulez effectuer un
                                                séjour de moins de 90 jours à l’étranger, nous
                                                vous accompagnons dans la constitution de votre dossier.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Bourse d’études</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Vous voulez obtenir une bourse
                                                d’étude pour l’étranger, recevoir des appels à
                                                candidatures correspondant à votre profil académique, avoir un coaching
                                                pour votre candidature, faire payer des frais de dossier à l’étranger,
                                                nous mettons notre
                                                expertise à votre disposition. Nous travaillons avec des anciens
                                                boursiers qui
                                                partageront avec vous leur expérience.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Traduction</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Si vous avez besoin d’une
                                                traduction assermentée de vos diplômes à l’étranger,
                                                nous nous chargeons de toute la procédure et vous recevez l’original de
                                                votre
                                                diplôme traduit et signé sur place au Cameroun. <br/>Si vous avez besoin
                                                de traductions assermentées au Cameroun, notre équipe de
                                                traductrices et traducteurs chevronnés est à votre disposition. La
                                                qualité de service
                                                rime ici avec célérité et professionnalisme.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Equivalence des diplômes</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Si vous avez besoin de faire
                                                effectuer l’équivalence de vos diplômes en Allemagne,
                                                en Belgique, en France, au Canada, en Angleterre, en Suisse ou aux
                                                Etats-Unis,
                                                nous nous chargeons de toute la procédure et vous recevez votre
                                                équivalence sur
                                                place au Cameroun.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Achat de billets d’avion</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Peu importe votre destination,
                                                vous pouvez acheter votre billet d’avion chez nous à
                                                des prix raisonnables.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Réservation
                                            d’hôtel/Airbnb</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>Pour toute vos réservations
                                                d’hôtel dans votre pays de destination, vous nous payer
                                                en FCFA au Cameroun et nous effectuons la réservation à votre place.
                                                Vous recevez
                                                tous les documents nécessaires par mail. <br/> Nous pouvons également
                                                vous faire une réservation sur Airbnb, peu importe votre
                                                destination.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h4 style={{fontWeight: 'bold', color: '#30D52E'}}>Les pays que nous
                                            couvrons</h4>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i>La Belgique</li>
                                            <li><i className="ri-check-double-line"></i>L’Allemagne</li>
                                            <li><i className="ri-check-double-line"></i>La France</li>
                                            <li><i className="ri-check-double-line"></i>La Suisse</li>
                                            <li><i className="ri-check-double-line"></i>Le Luxembourg</li>
                                            <li><i className="ri-check-double-line"></i>Le Canada</li>
                                            <li><i className="ri-check-double-line"></i>L’Angleterre</li>
                                            <li><i className="ri-check-double-line"></i> Les Etats-Unis</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane" id="tab-2">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Venir au Cameroun</h3>

                                        <p className="fst-italic">
                                            Nous vous facilitons beaucoup de procédures au Cameroun.
                                        </p>
                                        <h5 style={{fontWeight: 'bold', color: '#30D52E'}}>Stage académique</h5>
                                        <p>Grace à nos contacts, à nos partenaires et aux entreprises qui nous font
                                            confiance,
                                            nous pouvons vous trouver un stage académique au Cameroun dans une
                                            entreprise
                                            sérieuse et Professionnelle.</p>
                                        <h5 style={{fontWeight: 'bold', color: '#30D52E'}}>Volontariat</h5>
                                        <p>Au travers de notre intermédiaire, vous pouvez trouver un contrat de
                                            volontariat au
                                            Cameroun dans une école, un hôpital, un centre de santé, un orphelinat, un
                                            centre
                                            social, etc. Que ce soit dans les grandes métropoles ou dans les zones
                                            reculées du
                                            pays, nous vous accompagnons et nous guidons dans le choix de votre
                                            destination. <br/> Si nécessaire, nous nous assurons de la mise à votre
                                            disposition d’un logement dans
                                            un appartement meublé, en fonction de la zone et des possibilités sur place
                                            et si
                                            nécessaire, au choix. </p>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/came.jpeg" alt="" className="img-fluid"/>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h5 style={{fontWeight: 'bold', color: '#30D52E'}}>Meublés</h5>
                                        <p>Si vous avez besoin d’appartements meublés à Yaoundé, Douala, Bafoussam, Buea
                                            et dans d’autres localités du Cameroun, nous disposons d’un réseau
                                            d’appartements
                                            meublés dans toutes ces villes et nous pouvons mettre cela à votre
                                            disposition, selon
                                            le standing et la période qui correspondent à vos besoins.</p>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h5 style={{fontWeight: 'bold', color: '#30D52E'}}>Véhicule/chauffeur</h5>
                                        <p>Que vous soyez résident permanent ou en court séjour privé au Cameroun, nous
                                            avons des véhicules de différents standing à mettre à votre
                                            disposition. <br/>
                                            Si vous avez un véhicule, nous pouvons également vous proposer les services
                                            de
                                            nos chauffeurs, en fonction de vos besoins.</p>
                                    </div>
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <h5 style={{fontWeight: 'bold', color: '#30D52E', marginTop: 10}}>Documents administratifs</h5>
                                        <p>Vous vivez à l’étranger et vous avez besoin d’obtenir des documents
                                            administratifs
                                            au Cameroun ? Nous pouvons vous aider en activant notre réseau pour que vous
                                            obteniez les documents en question dans les délais raisonnables et nous nous
                                            chargeons de vous les faire parvenir en toute sécurité via notre service
                                            d’expédition
                                            de colis.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane" id="tab-3">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Cours de langue</h3>
                                        <p className="fst-italic">
                                            Nous vous proposons des cours aussi bien en présentiel qu’à distance. Du A1
                                            au
                                            C1, nous nous assurons que vous êtes suivis par des enseignants chevronnés.
                                            Nous vous préparons au <span style={{fontWeight: 'bold'}}>Start Deutsch, Zdaf, TestDaf, TOEFL, IELTS et aux
                            examens officiels d’italien et du chinois.</span>
                                        </p>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i> Allemand.</li>
                                            <li><i className="ri-check-double-line"></i> Anglais</li>
                                            <li><i className="ri-check-double-line"></i> Chinois.</li>
                                            <li><i className="ri-check-double-line"></i> Italien.</li>
                                        </ul>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/langue.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-4">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Omnis fugiat ea explicabo sunt dolorum asperiores sequi inventore rerum</h3>
                                        <p>
                                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate
                                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                            cupidatat non proident, sunt in
                                            culpa qui officia deserunt mollit anim id est laborum
                                        </p>
                                        <p className="fst-italic">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore
                                            magna aliqua.
                                        </p>
                                        <ul>
                                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip
                                                ex ea commodo consequat.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Duis aute irure dolor in
                                                reprehenderit in voluptate velit.
                                            </li>
                                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip
                                                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                                voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                                                pariatur.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/tabs-4.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </>
        )
    }
}