import React from 'react';
import {Helmet} from "react-helmet";
import Section from "./Section";

class Faq extends React.Component {
    render() {
        return(
            <>
                <Section btn={<a href="#faq" className="btn-get-started scrollto">Voir Plus</a>} text1="Frequently" text2="Asked" text3="Questions." image="../../assets/img/Pic12.jpg" />
                <section id="faq" className="faq">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Frequently Asked Questions</h2>
                        </div>

                        <ul className="faq-list accordion" data-aos="fade-up">

                            <li>
                                <a data-bs-toggle="collapse" className="collapsed" data-bs-target="#faq1">Proposez-vous
                                    des séances de préparation pour les entretiens du visa? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Oui ! Nous proposons des séances de préparation pour tout type de visa. Nous
                                        nous
                                        assurons que votre dossier est complet et nous procédons à près d’une dizaine de
                                        séances de simulation au cours desquelles vous serez mis en condition
                                        d'entretien.
                                        Sur la base de notre expérience avec des dizaines de candidats et des techniques
                                        pratiques acquises jusqu'ici, vous aurez la possibilité de voir l'amélioration
                                        considérable sur une courbe ascendante de votre speech. Si vous avez avez
                                        exclusivement besoin de coaching pour votre entretien visa, vous pouvez nous
                                        contacter.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq2" className="collapsed">Proposez-vous
                                    des séances de préparation pour les entretiens
                                    d’embauche? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Tout comme les séances de simulations pour visa, nous proposons également des
                                        séances de préparation pour les entretiens d’embauche. Nous procédons à près
                                        d’une dizaine de séances de simulation au cours desquelles nous donnons les
                                        techniques de réponse qui vous permettront de mieux vous vendre pendant
                                        l’entretien en question et d’obtenir votre contrat de travail..
                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq3" className="collapsed">Est-ce que
                                    vous garantissez l’obtention du visa? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        La délivrance d’un visa ne dépend que de l'autorité consulaire qui est habilitée
                                        à
                                        délivrer le visa pour son pays. Notre travail est de nous assurer que votre
                                        dossier est
                                        complet, qu’il remplit toutes les conditions et que les réponses que vous
                                        donnerez
                                        pendant l’entretien du visa ne vous seront pas préjudiciables. D’un dossier à un
                                        autre, d'une catégorie de visa à une autre et d’un pays à un autre, la
                                        probabilité ne
                                        sera jamais la même et nos séances de simulation servent à rapprocher vos
                                        chances
                                        le plus possible de 95% au moins.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq4" className="collapsed">Les produits
                                    entreposés chez nous peuvent-ils rester éternellement? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Les produits entreposés dans nos locaux doivent être récupérés par les personnes
                                        ou les clients concernés dans les délais inscrits dans le contrat qui nous lie
                                        au
                                        responsable dudit produit. S’il advenait qu’un produit devait rester plus
                                        longtemps
                                        que prévu, nous trouverons un terrain d'entente avec le responsable ou
                                        l'entreprise à
                                        qui le produit appartient.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq5" className="collapsed">Comment
                                    garantissez-vous que les colis arriveront à bon port ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour vous rassurer de nous avoir fait confiance, nous vous informerons
                                        continuellement sur la localisation de votre colis par messages. Vous recevrez
                                        également le contact de la personne qui voyagera avec votre coli et si
                                        nécessaire, le
                                        contact de la personne auprès de qui le coli pourra être récupéré une fois dans
                                        le
                                        pays où la ville de destination du voyageur.

                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq6" className="collapsed">Comment se
                                    déroule le système d’échange une fois dans le pays
                                    d'arrivée ? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour récupérer votre coli, nous allons vous passer les coordonnées téléphoniques
                                        de
                                        la personne qui transportera vos colis et ou de la personne auprès de qui les
                                        colis
                                        seront récupérés. Nous nous assurons également que vous recevez une
                                        confirmation au décollage et à l'atterrissage de vos colis. Vous pouvez, selon
                                        votre
                                        choix, recevoir votre coli par la poste (à vos frais) ou aller le récupérer sur
                                        place en
                                        personne. Une fois le coli récupéré, nous vous prions de nous envoyer un message
                                        de confirmation. La qualité de service est une priorité pour nous. Nous sommes
                                        ouverts à tous vos feedbacks et vos suggestions d'amélioration, d'optimisation
                                        et de
                                        simplification de notre système d'envoi et de réception de colis.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq7" className="collapsed">Comment
                                    récupérer son colis au Cameroun ? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Vous pouvez récupérer votre coli à Yaoundé, directement dans nos locaux sis au
                                        quartier Messassi ou à Douala, sur arrangement avec notre personne contact sur
                                        place.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq8" className="collapsed">Peut-on faire
                                    des stages académiques ou professionnels chez DiPCo ?
                                    Comment en bénéficier ? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Il est possible de faire un stage académique dans notre entreprise, dans la
                                        limite de
                                        nos capacités d'accueil et de nos besoins réels. Les offres de stages seront
                                        communiquées via nos canaux de communication, mais il est toujours possible
                                        d'envoyer une candidature sur initiative propre.

                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq9" className="collapsed">Il faut quel
                                    niveau de langue pour faire une formation professionnelle en
                                    Allemagne ?<i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour avoir un visa formation professionnelle pour l'Allemagne à partir du
                                        Cameroun,
                                        il faut, selon les informations sur le site internet de l'ambassade d'Allemagne
                                        à
                                        Yaoundé, avoir le B1. Seulement, plus de 95% d'écoles et centres de formation en
                                        Allemagne exigent le B2 et l'ambassade d'Allemagne exige également le B2, passe
                                        de temps à autre du B1 au B2. Donc il est fortement recommandé d'avoir le B2
                                        pour
                                        plus de chances et de sécurité dans la recherche du contrat de formation
                                        professionnelle et dans les procédures de visa. Vous pouvez encore optimiser vos
                                        chances en continuant jusqu'au C1.

                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq10" className="collapsed">Peut-on
                                    arriver en Allemagne avec un visa formation professionnelle et
                                    changer pour devenir étudiant ? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq10" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        La législation allemande n'autorise pas officiellement de quitter du statut
                                        formation
                                        professionnelle pour le statut étudiant. L'inverse par contre est possible. Donc
                                        en
                                        théorie, un étudiant peut changer son statut et faire une formation
                                        professionnelle
                                        alors qu'une personne avec le statut de formation professionnelle ne peut
                                        légalement
                                        pas devenir étudiant. Seulement, vous avez toujours la possibilité de vous
                                        inscrire
                                        dans des programmes d'étude à distance, mais vous devez savoir gérer votre temps
                                        parce que la charge de travail peut devenir insoutenable

                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq11" className="collapsed">Quel est
                                    l'âge limite pour avoir un visa formation professionnelle en
                                    Allemagne ? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq11" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        En théorie, il n'y a pas d'âge limite pour faire obtenir un visa pour formation
                                        professionnelle en Allemagne, seulement, nous recommandons aux personnes de
                                        plus de 33 ans de ne pas passer par cette voie, car l'ambassade pourrait
                                        compliquer
                                        pour plusieurs raisons. Toujours est-il qu'il est possible d'attaquer en justice
                                        les
                                        décisions de refus de refus de visa des services consulaires.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq12" className="collapsed">Peut-on avoir
                                    un visa étudiant pour la France, l'Allemagne, la Belgique
                                    ou le Canada à partir du Cameroun avec une moyenne en deçà de 12 au
                                    baccalauréat ou GCE A Level? <i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq12" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        La plupart des pays occidentaux accorde de plus en plus de visas aux profils
                                        académiques avec des résultats à partir de 12 de moyenne. Seulement, la moyenne
                                        n'est pas toujours le seul élément majeur. D'autres facteurs peuvent vous aider
                                        à
                                        obtenir un visa étudiant. Tout dépend du profil du demandeur de visa.
                                        Pour ce qui est particulièrement de l'Allemagne, le nouveau système de
                                        réservation
                                        des visas donne la possibilité aux personnes avec des diplômes de moins de 12 de
                                        moyenne de demander un visa dans la liste A, sous réserve que la personne ait un
                                        TESTDAF 4X4, ce qui correspond au niveau de langue C1. Là encore, il faut avoir
                                        un
                                        speech en béton pour justifier sa capacité et sa volonté d'étudier une fois en
                                        Allemagne.

                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq13" className="collapsed">Quel niveau
                                    de langue faut-il pour avoir un visa aupair ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq13" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour les pays comme la France, la Belgique, vous n'avez pas besoin d'un
                                        certificat
                                        de langue pour demander un visa aupair. Pour les pays non francophones comme
                                        l'Allemagne, l'Italie, l'Espagne etc, à partir du A2, vous pouvez déjà obtenir
                                        un visa
                                        aupair. Mais plus votre niveau de langue est élevé, plus vous avez de chances
                                        d'obtenir un visa aupair à partir du Cameroun. Seulement, le niveau de langue
                                        n'est
                                        pas le seul facteur qui entre en jeu lorsqu'il s'agit d'obtenir un visa aupair.
                                        Il faut, en
                                        plus d'avoir un dossier complet, avoir un argumentaire en béton pour convaincre
                                        les
                                        autorités consulaires en charge de votre dossier. Grâce à notre coaching pour
                                        l'entretien visa à partir du Cameroun, nous pouvons vous aider à
                                        considérablement
                                        optimiser votre speech et mettre encore plus de chances de votre côté.
                                        Allemagne.

                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq14" className="collapsed">Quel est
                                    l'âge limite pour avoir un visa aupair ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq14" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour avoir un visa aupair pour les pays de l'espace Shengen à partir du
                                        Cameroun,
                                        vous devez avoir moins de 27 ans à la date de demande de visa. Moins vous êtes
                                        âgée, plus vous avez des chances d'obtenir un visa aupair.
                                    </p>
                                </div>
                            </li>


                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq15" className="collapsed">Faut-il
                                    absolument une caution pour obtenir un visa étudiant à partir du
                                    Cameroun ?<i className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq15" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour étudier à l'étranger, peu importe le pays, l'on vous exigera de justifier
                                        comment
                                        vous allez survivre une fois dans le pays et financer vos études. Ainsi, vous
                                        pourrez
                                        être appelés à ouvrir un compte bloqué dans une banque du pays concerné. Le
                                        montant de la caution varie alors en fonction des pays et des législations. Il
                                        oscille,
                                        dans la plupart des cas, entre deux et 15 millions. Si vous avez besoin
                                        d'assistance
                                        pour ouvrir un compte bloqué à l'étranger, vous pouvez nous
                                        contacter. <br/> Seulement, il existe d'autres moyens de contournement comme les
                                        prises en charge
                                        et les tuteurs. Dans ces cas, vous devez avoir un document légal signé par les
                                        autorités du pays concerné prouvant que la personne dont le nom figure sur le
                                        document a les moyens nécessaires pour vous prendre financièrement en charge ou
                                        être votre caution morale. S'il ne s'agit pas d'un membre de votre famille ou
                                        d'une
                                        connaissance, vous devez généralement trouver un arrangement avec la personne
                                        concernée.
                                    </p>
                                </div>
                            </li>


                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq16" className="collapsed">Peut-on
                                    devenir étudiant après un séjour aupair ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq16" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Après le séjour aupair, vous avez la possibilité d'obtenir un séjour étudiant ou
                                        un
                                        séjour formation professionnelle, ou même tout autre type de statut légal dans
                                        votre
                                        pays d'accueil. Le plus important ici étant de disposer de tous les arguments
                                        légaux
                                        qui vous permettront d'obtenir l'accord des autorités locales en charge de votre
                                        dossier.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq17" className="collapsed">Quel niveau
                                    de langue faut-il pour avoir un visa volontariat ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq17" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Pour ce qui est du visa volontariat, en fonction de votre pays d'accueil, le
                                        niveau A2
                                        suffit généralement. Mais comme dans le cas du visa aupair, un niveau de langue
                                        plus élevé est recommandé et l'obtention du visa dépend également ici de votre
                                        capacité à justifier la nécessité de ce séjour volontariat.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <a data-bs-toggle="collapse" data-bs-target="#faq18" className="collapsed">Peut-on
                                    devenir étudiant après son année de volontariat ? <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-x icon-close"></i></a>
                                <div id="faq18" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Comme dans le cas du visa aupair également, vous pouvez devenir étudiant après
                                        un séjour volontariat, seulement vous devez disposer de tous les documents
                                        nécessaires pour cela.
                                    </p>
                                </div>
                            </li>

                        </ul>

                    </div>
                </section>

            </>
        )
    }
}

export default  Faq