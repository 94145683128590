import React from 'react';

export  class Team extends React.Component {
    render() {
        return(
            <section id="team" class="team section-bg">
                <div class="container" data-aos="fade-up">
        
                <div class="section-title">
                    <h2>Notre Equipe</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p>
                </div>
        
                <div class="row">
        
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="member" data-aos="fade-up" data-aos-delay="100">
                        <div class="member-img">
                        <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""/>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                        </div>
                        </div>
                        <div class="member-info">
                        <h4>Landry</h4>
                        <span>Chief Executive Officer</span>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="member" data-aos="fade-up" data-aos-delay="200">
                        <div class="member-img">
                        <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""/>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                        </div>
                        </div>
                        <div class="member-info">
                        <h4>Landry </h4>
                        <span>Product Manager</span>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="member" data-aos="fade-up" data-aos-delay="300">
                        <div class="member-img">
                        <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""/>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                        </div>
                        </div>
                        <div class="member-info">
                        <h4>Landry</h4>
                        <span>CTO</span>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="member" data-aos="fade-up" data-aos-delay="400">
                        <div class="member-img">
                        <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""/>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                        </div>
                        </div>
                        <div class="member-info">
                        <h4>Landry</h4>
                        <span>Accountant</span>
                        </div>
                    </div>
                    </div>
        
                </div>
        
                </div>
          </section>
        )
    }
}