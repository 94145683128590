import React, {Component, Fragment} from 'react';

class Section extends Component {

    render() {

        return (
            <section id="hero" style={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${this.props.image})`,

                backgroundSize: 'cover',
                backgroundPosition: 'relative',
                paddingTop: 82,
            }} className="d-flex align-items-center">

                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <div className="row">
                        <div className="col-xl-6">
                            <h1> {this.props.text1} <br/> <span style={{color: '#FFB315'}}>{this.props.text2}</span> <br/> {this.props.text3}</h1>
                            <h2> {this.props.sl1} <span style={{color: '#30D52E'}}>{this.props.sl2}</span>  {this.props.sl3}</h2>
                            {/*<a href="#about" className="btn-get-started scrollto">A Propos</a>*/}
                            {this.props.btn}
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default Section;