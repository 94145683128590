import React from 'react';
import firebase from "../Firebase";

const db = firebase.firestore()
export  class Footer extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: {
                sent: false,
                value: ""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        const {email} = this.state
        console.log(email)
        if (this.state.email) {
            db.collection("newsletter").add({
                email
            }).then(() => {
                this.setState({
                    message: {sent: true, value: "Merci d'avoir souscrit a la NewsLetter"}, email: ''
                })
            }).catch(e => {
                this.setState({
                    message: {sent: false, value: "Une erreur est survenue lors de la souscription"}
                })
            })
        }else {
            this.setState({
                message: {sent: false, value: "veuillez entrer une adresse email"}
            })
        }
    }


    render() {
        return(
            <footer id="footer">

                <div class="footer-top">
                <div class="container">
                    <div class="row">

                    <div class="col-lg-6 col-md-6 footer-contact">
                        <h3>DiPCo<span>.</span></h3>
                        <p>
                        Cameroun <br/>
                        Yaoundé<br/>
                        Carrefour Messassi, immeuble Express Exchange, en face de la station Total<br/>
                        <strong>Telephone:</strong>+237 652597623/+237 697704965/+49 1573 0319385 <br/>
                        <strong>Email:</strong> <a href="mailto:administration@dipcogroup.com" target="Blank" >administration@dipcogroup.com</a> / <a href="mailto:contact@dipcogroup.com" target="Blank">contact@dipcogroup.com</a><br/>
                        </p>
                    </div>

                    {/* <div class="col-lg-2 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                        </ul>
                    </div> */}

                    <div class="col-lg-6 col-md-6 footer-newsletter">
                        <h4>Join Our Newsletter</h4>
                        <form onSubmit={this.handleSubmit} >
                             <input onChange={(e) => this.setState({email: e.target.value})} type="email" value={this.state.email} name="email"/><input type='submit' value="Subscribe" />
                        </form>
                        {(this.state.message.value != "" && this.state.message.sent == false) &&
                        <div className="alert alert-warning alert-dismissible fade show"
                             role="alert"> {this.state.message.value} </div>}
                        {(this.state.message.value != "" && this.state.message.sent == true) &&
                        <div className="alert alert-success alert-dismissible fade show"
                             role="alert">{this.state.message.value} </div>}
                    </div>

                    </div>
                </div>
                </div>

                <div class="container d-md-flex py-4">

                <div class="me-md-auto text-center text-md-start">
                    <div class="copyright">
                    &copy; Copyright <strong><span>DiPCo</span></strong> @2021. All Rights Reserved
                    </div>
                    <div class="credits">
                 
                    Designed by <a href="#">2fTechnology</a>
                    </div>
                </div>
                <div class="social-links text-center text-md-end pt-3 pt-md-0">
                    <a href="https://twitter.com/DiPCo237?t=83eYwUQ5EpGoaimz10_QNQ&s=09" target="Blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a href=" https://www.facebook.com/diaspoplacement/" class="facebook" target="Blank"><i class="bx bxl-facebook"></i></a>
                    {/* <a href="#" class="instagram" target="Blank"><i class="bx bxl-instagram"></i></a> */}
                    <a href="https://www.youtube.com/channel/UCCV-CqnrSav0sNckjf4k5ZQ" class="google-plus" target="Blank"><i class="bx bxl-youtube"></i></a>
                    <a href="https://www.linkedin.com/company/dipco-diaspo-placement-consulting" class="linkedin" target="Blank"><i class="bx bxl-linkedin"></i></a>
                </div>
                </div>
            </footer>
        )
    }

}