import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2eW8tSrJ-i9FIf1r-P2f4W5_ZDN6H8Tg",
  authDomain: "dipcoproject.firebaseapp.com",
  projectId: "dipcoproject",
  storageBucket: "dipcoproject.appspot.com",
  messagingSenderId: "1071589843465",
  appId: "1:1071589843465:web:11bdd4b903a5a8cc168ed8"
};

firebase.initializeApp(firebaseConfig);
firebase.functions();//.useFunctionsEmulator('http://localhost:5000');

//firebase.firestore().settings(settings);

export default firebase;
