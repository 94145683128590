import React, { Fragment } from 'react';
import Header from '../component/Header';
import firebase from "../Firebase";
import {Helmet} from "react-helmet";
import Section from "./Section";

const db = firebase.firestore().collection('users');
const storageRef = firebase.storage().ref();

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: true,
        };
    }


    render() {
        return(
            <Fragment>
                 <Helmet>
                    <script src="/js/main.js" ></script>
                    <title>A propos de Diapo Placement Consulting</title>
                </Helmet>
                <Section btn={<a href="#about" className="btn-get-started scrollto">Voir Plus</a>} text1="A Propos de" text2="DiPCo" image="../../assets/img/banner2.jpg" />
                <section id="about" class="about section-bg">
                    <div class="container" data-aos="fade-up">

                        <div class="row no-gutters">
                        <div class="content col-xl-5 d-flex align-items-stretch">
                            <div class="content">
                            <h3>Diaspo Placement Consulting </h3>
                            <p>
                              Diaspo Placement Consulting propose des solutions-service répondant aux problèmes des Camerounais(es) de la diaspora, des Camerounais(es) en processus d’émigration et des étrangers voulant venir au Cameroun pour des courts ou longs séjours.                              </p>
                            <a href="#about" class="about-btn"><span>A Propos</span> <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                        <div class="col-xl-7 d-flex align-items-stretch">
                            <div class="icon-boxes d-flex flex-column justify-content-center">
                            <div class="row">
                                <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                                <i class="bx bx-chevron-down-circle"></i>
                                <h4>Notre Vision</h4>
                                <p>Apporter du sourire, faciliter la mobilité</p>
                                </div>
                                <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                                <i class="bx bx-chevron-down-circle"></i>
                                <h4>Notre Mission</h4>
                                <p >
                                 <span style={{fontWeight: 'bold', color: '#30D52E'}}>Accompagner</span> : l’objectif premier notre vision-mission est d’accompagner des milliers
                                de personnes dans leur processus de mobilité, qu’ils soient encore en cours
                                d’émigration, qu’ils soient déjà installés dans leur pays d’accueil, qu’ils veillent faire
                                bouger des biens,des capitaux, qu’ils veuillent séjourner temporairement au
                                Cameroun ou alors qu’ils veuillent rentrer définitivement s’installer sur place <br />
                                Nous voulons également accompagner les personnes désirant venir au Cameroun à
                                trouver sur place des solutions qui répondent à leurs questions, afin de leur garantir
                                un séjour agréable dans notre pays.
                                </p>
                                <p>
                                 <span style={{fontWeight: 'bold', color: '#30D52E'}}>Faciliter</span> :
                                  Nos services sont des solutions concrètes et pragmatiques qui facilitent
                                  votre mobilité, la mobilité de vos biens et de vos capitaux.
                                </p>
                                <p>
                                 <span style={{fontWeight: 'bold', color: '#30D52E'}}>Placer</span> :
                                 Placer chacun de nos clients au bon endroit, à la bonne place, selon son
                                    souhait et ses objectifs de vie
                                </p>
                                </div>
                                <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="300">
                                <i class="bx bx-chevron-down-circle"></i>
                                <h4>Nos Valeurs</h4>
                                <p>
                                <span style={{fontWeight: 'bold', color: '#30D52E'}}>La confidentialité</span> :
                                 Nous sommes conscients de la délicatesse des données et
                                    documents que vous mettez à notre disposition. Alors nous mettons un point
                                    d’honneur à les garder de façon confidentielle et à les effacer de notre base de
                                    données le plus tôt possible.
                                </p>
                                <p>
                                <span style={{fontWeight: 'bold', color: '#30D52E'}}>La discrétion</span> :
                                 Aussi bien sur le terrain qu’à distance, nous opérons en toute
                                discrétion sans jamais dévoiler les clauses de notre accord.
                                </p>
                                <p>
                                <span style={{fontWeight: 'bold', color: '#30D52E'}}>Le professionnalisme</span> :
                                Notre service client se veut des plus regardant sur le rapport
                                avec notre clientèle. En toute situation, professionnalisme, respect et courtoisie
                                resteront nos mots d’ordre.
                                </p>
                                <p>
                                <span style={{fontWeight: 'bold', color: '#30D52E'}}>La confiance</span> :
                                Parceque votre confiance est l’élément de base dans notre partenariat,
                                nous œuvrons sans relâche à la préserver et la respecter.
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                       </div>
                    </section>
            </Fragment>
        )
    }
}

export default About;