import React, { Fragment } from 'react';
import Header from '../component/Header';
import firebase from "../Firebase";
import {Helmet} from "react-helmet";

const db = firebase.firestore().collection('users');
const storageRef = firebase.storage().ref();

class Partern extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: true,
        };
    }


    render() {
        return(
            <Fragment>
                 <Helmet>
                    <script src="/js/main.js" ></script>
                </Helmet>
                <section id="clients" class="clients">
                    <div class="container" data-aos="zoom-in">

                        <div class="clients-slider swiper-container">
                        <div class="swiper-wrapper align-items-center">
                            <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt=""/></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt=""/></div>
                        </div>
                        <div class="swiper-pagination"></div>
                        </div>

                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Partern;